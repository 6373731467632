body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.post-alert {
  padding: 15px 18px;
  border-radius: 18px;
  background-color: rgba(114, 128, 142, 0.6);
  color: #f4f4f5;
  transform: translate(0, 180px);
}

.image-alert {
  padding: 15px 18px;
  border-radius: 18px;
  background-color: rgba(114, 128, 142, 0.6);
  color: #f4f4f5;
  transform: translate(15px, 420px);
  white-space: nowrap;
}

@media (max-width: 1060px) {
  .image-alert {
    position: absolute;
    left: calc((100vw - 370px) / 2);
    transform: translate(0, 420px);
  }
}

:root {
  --vh: 100%;
}

html,
body {
  height: 100vh;
  height: var(--vh);
}
